<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header
                    title="Links"
                >
                    <template v-slot:description>
                        import { HbLink } from 'hummingbird-aviary';
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card title="Primary Links (Use for In-App Links) + Code" class="mt-4 mb-6">
            <div class="ma-6">
                <hb-link @click="success = true">Go to Action</hb-link>
            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-link @click="success = true">Go to Action&lt;/hb-link>
</pre>

            </hb-card>
        </hb-card>

        <hb-card title="Secondary Links (Use for External Links) + Code" class="mt-4 mb-6">
            <div class="ma-6">
                <hb-link color="secondary" @click="success = true">email@email.com</hb-link>
            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-link color="secondary" @click="success = true">email@email.com&lt;/hb-link>
</pre>

            </hb-card>
        </hb-card>

        <hb-card title="Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="Events" class="my-6 pb-1">
            <hb-data-table
                :headers="eventHeaders"
                :items="eventItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-global-notification
            v-model="success"
            type="success"
            description="The '@click' event was initiated."
        >
        </hb-global-notification>

    </div>
</template>

<script type="text/babel">

    export default {
        name: "DesignSystemLinks",
        data: function() {
            return {
                success: false,
                propHeaders: [
                    { text: 'Name', value: 'name', width: '110' },
                    { text: 'Type', value: 'type', width: '110' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: 'id', type: 'string', default: 'undefined', description: 'Sets the id for the clickable element. Please always set an id for all links as we need this for automated testing.' },
                    { name: 'color', type: 'string', default: 'undefined', description: 'Sets the color of the link. The options are "primary" or "secondary" or do not set if you want primary color as that is the default visual output. Use "primary" color for in-app links (i.e. if app is hummingbird, and the link is to another hummingbird page, then use primary, etc...). Use "secondary" color for links to external sites/resources.' },
                    { name: 'to', type: 'string || object', default: 'undefined', description: 'Sets the vue route to go to when the link is clicked. For internal links. Accepts a string or object. See vue documentation on how to pass name, paths, params, query, etc...' },
                    { name: 'href', type: 'string', default: 'undefined', description: 'Sets the href value for the link. Usually for external links.' },
                    { name: 'target', type: 'string', default: 'undefined', description: 'Sets the "target" attribute value when "href" is also set. For example, set "target=_blank" to open in a new tab when clicked.' },
                    { name: 'dashed-underlined', type: 'string', default: 'undefined', description: 'Adds a dashed underline to the link that is colored: #101318.' },
                ],
                eventHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                eventItems: [
                    { name: '@click', description: 'Event for when link is clicked.' },
                ]
            }
        }
    }
</script>

<style scoped>

</style>